// EXEMPT
/* eslint-disable i18next/no-literal-string */
import React from "react";

import { hardCodeURLs } from "@/app/constants.mjs";
import {
  ContentWrapper,
  TextBlock,
  TextBody,
  TextSubBlock,
  TextSubTitle,
  TextTitle,
} from "@/app/Legal.style.jsx";
import { Anchor } from "@/app/PrivacyPolicy.jsx";

const PRIVACY_POLICY = "/legal/privacy-policy";
const TermsPage = () => {
  return (
    <ContentWrapper>
      <TextBlock>
        <TextTitle>Last Updated: 05 August, 2022</TextTitle>
        <TextBody>
          Blitz App, Inc. and/or its affiliates (“Blitz”, “we”, “us”, “our”, or
          “Sponsor”) offers a platform and software tools designed to enhance
          gaming experiences by empowering players with expert analytics
          designed to help them improve and win more, including the websites
          blitz.gg, probuilds.net, champion.gg, lolcounter.com,
          championselect.com, and dyno.gg (the “Websites”), the Blitz
          application (the “Blitz App”) and certain competitions organized,
          promoted and/or administered by us (each, a “Competition” and,
          together with the Websites and the Blitz App, the “Services”).
        </TextBody>
        <TextBody>
          Please read these Terms of Service (the “Terms”) and our{" "}
          <Anchor to={PRIVACY_POLICY} noBlank noExternal>
            Privacy Policy
          </Anchor>{" "}
          (“Privacy Policy”) carefully because they govern your use of our
          Services.
        </TextBody>
        <TextBody className="uppercase">
          WHEN YOU AGREE TO THESE TERMS YOU ARE AGREEING (WITH LIMITED
          EXCEPTION) TO RESOLVE ANY DISPUTE BETWEEN YOU AND BLITZ AND/OR ANY
          PRIZE PARTNER (DEFINED BELOW) THROUGH BINDING, INDIVIDUAL ARBITRATION
          RATHER THAN IN COURT. PLEASE REVIEW CAREFULLY SECTION 9 “BINDING
          ARBITRATION AND CLASS ACTION WAIVER” BELOW FOR MORE DETAILS.
        </TextBody>
      </TextBlock>
      <TextBlock>
        <TextTitle>1. Acceptance of Terms</TextTitle>
        <TextBody>
          These Terms are a contract between you and Blitz. By using the
          Services (including accessing the Websites, downloading and/or using
          the Blitz App, and/or entering a Competition), you agree to be bound
          by these Terms, our{" "}
          <Anchor to={PRIVACY_POLICY} noBlank noExternal>
            Privacy Policy
          </Anchor>
          , and all applicable laws and regulations (“Applicable Laws”). You
          further agree that you are responsible for compliance with any
          Applicable Laws. If you do not agree with any of these Terms, you are
          prohibited from using or accessing the Services.
          <br />
          We may update the Terms from time to time in our sole discretion. If
          we do, we will let you know by posting the updated Terms on the
          applicable Websites, the Blitz App and/or via e-mail or as in-app
          notifications. It is important that you review the Terms whenever we
          update them. By continuing to use the Services after we have posted
          updated Terms, you accept and agree to the changes. If you do not
          agree to the changes, you may not use the Services anymore.
        </TextBody>
      </TextBlock>
      <TextBlock>
        <TextTitle>2. Intellectual Property</TextTitle>
        <TextBody>
          Blitz and its licensors own all title, ownership rights, and
          intellectual property rights in the Services. Except as expressly
          provided in these Terms, you are prohibited from copying, reproducing,
          modifying, distributing, displaying, performing or transmitting any of
          the Services or the contents of the Services for any purposes, and
          nothing otherwise stated or implied in the Services confers on you any
          license or right to do so.
          <br />
          Blitz, the names of each Service, and their respective logos, are
          trademarks or registered trademarks of Blitz and its affiliates in the
          United States of America and elsewhere (“Trademarks”). You are
          expressly prohibited from using any Trademarks, except as expressly
          provided in these Terms, and nothing otherwise stated or implied in
          the Services confers on you any license or right to do so.
          Unauthorized use of any Trademarks, including reproduction, imitation,
          dilution or confusing or misleading uses, is also prohibited under the
          trademark laws of the United States and other countries.
        </TextBody>
      </TextBlock>
      <TextBlock>
        <TextTitle>3. Use License</TextTitle>
        <TextBody>
          <TextBody>
            Blitz grants you a non-exclusive, non-transferable,
            non-sublicensable limited right and license to install and use one
            copy of the Blitz App on compatible devices you own or control
            solely for your personal, non-commercial entertainment use (the
            “License”). The License is subject to the terms of this Agreement,
            and you may only make use of the License if you comply with them.
            <br />
            You may not:
          </TextBody>
          <ul>
            <li>modify or copy the materials</li>
            <li>
              use the materials for any commercial purpose, or for any public
              display (commercial or non-commercial)
            </li>
            <li>
              attempt to decompile or reverse engineer any software contained on
              Blitz&apos;s websites;
            </li>
            <li>
              remove any copyright or other proprietary notations from the
              materials; or
            </li>
            <li>
              transfer the materials to another person or “mirror” the materials
              on any other server.
            </li>
            <li>
              access or use any Services in violation of{" "}
              <Anchor to={hardCodeURLs.LEGAL_BIS_DOC}>
                United States export control and economic sanctions requirements
              </Anchor>
              ; or
            </li>
            <li>
              behave in a manner which is detrimental to the enjoyment of the
              Services by other users as intended by Blitz, in Blitz&apos;s sole
              judgment.
            </li>
          </ul>
        </TextBody>
        <TextBody>
          This License shall automatically terminate if you violate any of these
          restrictions and may be terminated by Blitz at any time. Upon
          termination of this License, you must destroy any downloaded materials
          in your possession whether in electronic or printed format. The Blitz
          App is licensed, not sold, to you under the License. No license or
          other rights shall be created hereunder by implication, estoppel, or
          otherwise.
        </TextBody>
      </TextBlock>
      <TextBlock>
        <TextTitle>4. Registration and Account Information</TextTitle>
        <TextBody>
          Registration may be required for certain portions of the Services. We
          will not grant any user access to any registration-required portions
          of the Services unless such user has completed the necessary
          registration and paid the fees, if any, associated with access to such
          portion of the Services. With respect to any such registration, you
          agree to: (i) provide true, accurate and complete information about
          yourself as prompted by the registration form (“Registration Data”)
          and (ii) maintain and promptly update the Registration Data to keep it
          true, accurate, current and complete. If Blitz has reasonable grounds
          to suspect that the Registration Data is untrue, inaccurate or
          incomplete, Blitz will have the right to suspend or terminate your
          account and refuse any and all current or future use of the Services.
          You acknowledge and agree that Blitz may rely on the Registration Data
          to send you important information and notices regarding your account
          and the Services. <br />
          You are responsible for maintaining the confidentiality of your
          password and any membership account information. You agree to
          immediately notify us of any unauthorized use of your password or
          other membership account information and further agree to indemnify
          and hold Blitz, its affiliates, operational providers and partners
          harmless for any improper or illegal use of your password. Blitz uses
          your username, password and other Registration Data in accordance with
          our{" "}
          <Anchor to={PRIVACY_POLICY} noBlank noExternal>
            Privacy Policy
          </Anchor>
          .
        </TextBody>
      </TextBlock>
      <TextBlock>
        <TextTitle>5. Paid Services</TextTitle>
        <TextSubBlock>
          <TextSubTitle>5.1 Payments and Billing. </TextSubTitle>
          <TextBody>
            Some Services require paid subscriptions and the acceptance of
            supplemental terms to access. When you provide payment information
            for a paid Service, you represent and warrant that the information
            is accurate, that you are authorized to use the payment method
            provided, and that you will notify us of changes to the payment
            information. We reserve the right to utilize third party payment
            card updating services to obtain current expiration dates on credit
            cards and debit cards. If you pay a periodic subscription fee for a
            Service, we will provide you with reasonable notice of changes to
            the fees or billing methods in advance of their effective date and
            you will be able to cancel your subscription prior to such change.
          </TextBody>
        </TextSubBlock>
        <TextSubBlock>
          <TextSubTitle>5.2 Auto-Renewals</TextSubTitle>
          <TextBody>
            <strong>
              By signing up for a subscription, you agreed that your
              subscription will be automatically renewed and, unless you cancel
              your subscription, you authorized us to charge your payment method
              for the renewal term.
            </strong>{" "}
            You agree that we can change the terms of the subscription with
            advance notice to you and an opportunity for you to cancel. The
            period of auto-renewal will be the same as your initial subscription
            period unless otherwise disclosed to you. The renewal rate will be
            no more than the rate for the immediately prior subscription period,
            excluding any promotional and discount pricing, unless we notify you
            of a rate change prior to your auto-renewal, in which case you will
            have the right to cancel the renewal of your subscription.
          </TextBody>
        </TextSubBlock>
        <TextSubBlock>
          <TextSubTitle>5.3 Free Trial</TextSubTitle>
          <TextBody>
            From time to time, we may offer a free trial subscription for a
            Service. If you register for a free trial subscription, we will
            begin to bill your account when the free trial subscription expires,
            unless you cancel your subscription before that time.
          </TextBody>
        </TextSubBlock>
        <TextSubBlock>
          <TextSubTitle>5.4 Cancellation</TextSubTitle>
          <TextBody>
            Unless otherwise disclosed when you subscribe, you have the right to
            cancel your Service subscription. When a subscription is canceled,
            you will not receive a prorated refund, but you will continue to
            have access to the applicable Service until the end of the term
            during which you canceled the subscription. If you cancel your
            subscription, you will still be obligated to pay other charges
            incurred by you in the course of using the Service prior to the date
            of cancellation.
          </TextBody>
        </TextSubBlock>
      </TextBlock>
      <TextBlock>
        <TextTitle>6. Competitions</TextTitle>
        <TextBody>
          These Terms apply to all Competitions. Competitions will also have
          additional terms, and you will be required to agree to such additional
          terms as a condition to entering a Competition. Competitions may
          involve a prize partner or other brand partner (“Prize Partner”) who
          supplies prizes for a Competition, receives branding in connection
          with a Competition and/or receives certain aggregated and
          de-identified information about the Competition. For Competitions
          involving a Prize Partner, the Prize Partner will have no liability or
          responsibility for a Competition or the prize distribution but will be
          solely responsible for honoring any prize gift codes, coupons or
          vouchers. The Prize Partner is intended to be a third-party
          beneficiary of these Terms. The Blitz App is compliant with Riot
          Games&apos; terms and conditions. In the event the Blitz App or the
          Competition conflict with or are found to be in violation of Riot
          Games&apos; terms and conditions or other rules, Blitz may alter, or
          terminate a Competition at any time.
        </TextBody>
      </TextBlock>
      <TextBlock>
        <TextTitle>7. Disclaimers and Limitation on Liability</TextTitle>
        <TextBody>
          THE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS,
          WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. WITHOUT
          LIMITING THE FOREGOING, WE EXPLICITLY DISCLAIM ANY IMPLIED WARRANTIES
          OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT
          AND NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF
          DEALING OR USAGE OF TRADE. We make no warranty that the Services will
          meet your requirements or be available on an uninterrupted, secure, or
          error-free basis. We make no warranty regarding the quality, accuracy,
          timeliness, truthfulness, completeness or reliability of any
          information or content on the Services. You acknowledge that Blitz has
          no control over, and no duty to take any action regarding what effects
          the Services may have on you; how you may interpret or use the
          Services; or what actions you may take as a result of having been
          exposed to the Services.
          <br />
          Blitz is not responsible or liable for any damage, loss or injury
          resulting from, relating to or arising out of (1) use, access or
          attempted use or access of the Services; (2) downloading any
          information during the course of use of the Serviced; and/or (3)
          violation of these Terms by other users. We have no responsibility to
          enforce these terms for the benefit of any user. <br />
          Some states do not allow the disclaimer of implied warranties, as such
          the foregoing disclaimer may not apply to you in its entirety.
          <br />
          TO THE MAXIMUM EXTENT PERMITTED BY THE LAW OF THE APPLICABLE
          JURISDICTION, IN NO EVENT WILL BLITZ&apos;S TOTAL LIABILITY ARISING
          OUT OF OR IN CONNECTION WITH THESE TERMS, ADDITIONAL TERMS, OR FROM
          THE USE OF OR INABILITY TO USE THE SERVICES EXCEED THE GREATER OF THE
          AMOUNTS YOU HAVE PAID FOR AVAILING OF THE SERVICES FORMING THE SUBJECT
          MATTER OF THE GRIEVANCE AND FIFTY DOLLARS ($50).THE EXCLUSIONS AND
          LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE
          BASIS OF THE BARGAIN BETWEEN BLITZ AND YOU.
        </TextBody>
      </TextBlock>
      <TextBlock>
        <TextTitle>8. Indemnification</TextTitle>
        <TextBody>
          By using the Services, including entering in a Competition, you agree
          to indemnify, defend, and hold harmless Blitz, any Prize Partner, and
          their affiliates, designees, parents, subsidiaries, employees,
          directors, officers, representatives and agents from: (a) any and all
          claims, demands, causes of action and judgments (including
          attorney&apos;s fees, court costs and expert&apos;s fees) arising out
          of the use of any of the Services, Riot Games, and League of Legends,
          or any other video game or video game publisher, as well as the
          conduct of a Competition, your violation of these Terms or any
          additional terms that may apply to your use of the Services, your
          receipt, ownership, use or misuse of any prize, and any of your acts
          or omissions that implicate publicity rights, defamation, or invasion
          of privacy. Blitz reserves the right, at its own expense, to assume
          exclusive defense and control of any matter otherwise subject to
          indemnification by you, and in such case, you agree to cooperate with
          us in the defense of such matters.
        </TextBody>
      </TextBlock>
      <TextBlock>
        <TextTitle>8. Indemnification</TextTitle>
        <TextBody>
          By using the Services, including entering in a Contest, you agree to
          indemnify, defend, and hold harmless Blitz, any Prize Partner, and
          their affiliates, designees, parents, subsidiaries, employees,
          directors, officers, representatives and agents from: (a) any and all
          claims, demands, causes of action and judgments (including
          attorney&apos;s fees, court costs and expert&apos;s fees) arising out
          of the use of Blitz, Riot Games, and League of Legends, as well as the
          conduct of a Contest, your violation of these Terms, Additional Terms,
          your receipt, ownership, use or misuse of any prize, and any of your
          acts or omissions that implicate publicity rights, defamation, or
          invasion of privacy. This includes the use by the Host of
          Entrant&apos;s entry submission or Winner&apos;s Material as set forth
          here and in Additional Terms. Blitz reserves the right, at its own
          expense, to assume exclusive defense and control of any matter
          otherwise subject to indemnification by you, and in such case, you
          agree to cooperate with us in the defense of such matters.
        </TextBody>
      </TextBlock>
      <TextBlock>
        <TextTitle>9. Binding Arbitration and Class Action Waiver</TextTitle>
        <TextBody>
          PLEASE READ THIS SECTION CAREFULLY – IT MAY SIGNIFICANTLY AFFECT YOUR
          LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT.
        </TextBody>
        <TextSubBlock>
          <TextSubTitle>9.1 Initial Dispute Resolution</TextSubTitle>
          <TextBody>
            Our Customer Support Department is available by email at
            support@blitz.gg any concerns you may have regarding the Services.
            Our Customer Service Department can resolve most concerns quickly to
            our users&apos; satisfaction. Failure to engage in this process
            could result in the award of fees against you in arbitration.
          </TextBody>
        </TextSubBlock>
        <TextSubBlock>
          <TextSubTitle>9.2 Binding Arbitration</TextSubTitle>
          <TextBody>
            If the parties do not reach an agreed upon solution within a period
            of 30 days from the time informal dispute resolution begins under
            the Initial Dispute Resolution provision, then either party may
            initiate binding arbitration as the sole means to resolve claims,
            subject to the terms set forth below. Specifically, by using the
            Services, including entering in a Competition, you agree that should
            a dispute arise in any manner concerning or relating to this
            Agreement between you and Blitz and/or any Prize Partner, including
            the arbitrability of this Agreement, other than injunctive relief
            and equitable relief, the dispute shall be submitted to and settled
            by binding and confidential arbitration administered by JAMS in
            accordance with the provisions of the Streamlined Arbitration and
            Procedures, excluding any rules or procedures governing class or
            representative actions. <br />
            Except as set forth in Section 9.5, the arbitrator, and not any
            federal, state or local court or agency, shall have exclusive
            authority to resolve all disputes arising out of or relating to the
            interpretation, applicability, enforceability or formation of these
            Terms, including, but not limited to any claim that all or any part
            of these Terms are void or voidable, whether a claim is subject to
            arbitration, and any dispute regarding the payment of JAMS
            administrative or arbitrator fees (including the timing of such
            payments and remedies for nonpayment). The arbitrator shall be
            empowered to grant whatever relief would be available in a court
            under law or in equity. The parties agree that the arbitrator may
            allow the filing of dispositive motions if they are likely to
            efficiently resolve or narrow issues in dispute. The
            arbitrator&apos;s award shall be written, and binding on the parties
            and may be entered as a judgment in any court of competent
            jurisdiction. No arbitration award or decision will have any
            preclusive effect as to issues or claims in any dispute with anyone
            who is not a named party to the arbitration. <br />
            The Streamlined Arbitration Rules governing the arbitration may be
            accessed at www.jamsadr.com or by calling JAMS at (800) 352-5267. If
            you commence arbitration in accordance with these Terms, you will be
            required to pay $250 to initiate the arbitration. To the extent the
            filing fee for the arbitration exceeds the cost of filing a lawsuit,
            the arbitrator may require Blitz to pay the additional cost. You are
            responsible for your own attorneys&apos; fees unless the arbitration
            rules and/or applicable law provide otherwise. <br />
            Any arbitration demand or counterclaim asserted by either party must
            contain sufficient information to provide fair notice to the other
            party of the asserting party&apos;s identity, the claims being
            asserted, and the factual allegations on which they are based. The
            arbitrator and/or JAMS may require amendment of any demand or
            counterclaim that does not satisfy these requirements. The
            arbitrator has the right to impose sanctions in accordance with JAMS
            Rule 24 for any claims the arbitrator determines to be frivolous or
            improper (under the standard set forth in Federal Rule of Civil
            Procedure 11). <br />
            The parties agree that JAMS has discretion to modify the amount or
            timing of any administrative or arbitration fees due under
            JAMS&apos;s Rules where it deems appropriate, provided that such
            modification does not increase the costs to you, and you waive any
            objection to such fee modification. The parties also agree that a
            good-faith challenge by either party to the fees imposed by JAMS
            does not constitute a default, waiver, or breach of this Section 9
            while such challenge remains pending before JAMS, the arbitrator,
            and/or a court of competent jurisdiction. <br />
            The parties understand that, absent this mandatory provision, they
            would have the right to sue in court and have a jury trial. They
            further understand that, in some instances, the costs of arbitration
            could exceed the costs of litigation and the right to discovery may
            be more limited in arbitration than in court. If you demonstrates
            that the costs of arbitration will be prohibitive as compared to the
            costs of litigation, Blitz will pay as much of the administrative
            costs and arbitrator&apos;s fees required for the arbitration as the
            arbitrator deems necessary to prevent the cost of the arbitration
            from being prohibitive. In the final award, the arbitrator may
            apportion the costs of arbitration and the compensation of the
            arbitrator among the parties in such amounts as the arbitrator deems
            appropriate.
          </TextBody>
        </TextSubBlock>
        <TextSubBlock>
          <TextSubTitle>9.3 Location</TextSubTitle>
          <TextBody>
            Arbitration will take place in Los Angeles, California or, if
            requested, some other location in the United States that is
            reasonably convenient for you.
          </TextBody>
        </TextSubBlock>
        <TextSubBlock>
          <TextSubTitle>9.4 Class Action Waiver</TextSubTitle>
          <TextBody>
            The parties further agree that any arbitration shall be conducted in
            their individual capacities only and not as a class action or other
            representative action, and the parties expressly waive their right
            to file a class action or seek relief on a class basis. YOU AND
            BLITZ AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN
            YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS
            MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. If there
            is a final judicial determination that applicable law precludes
            enforcement of this Paragraph&apos;s limitations as to a particular
            remedy, then that remedy (and only that remedy) must be severed from
            the arbitration and may be sought in court. The parties agree,
            however, that any adjudication of remedies not subject to
            arbitration shall be stayed pending the outcome of any arbitrable
            claims and remedies.
          </TextBody>
        </TextSubBlock>
        <TextSubBlock>
          <TextSubTitle>
            9.5 Exception - Litigation of Intellectual Property and Small Claims
            Court Claims
          </TextSubTitle>
          <TextBody>
            Notwithstanding the parties&apos; decision to resolve all disputes
            through arbitration, either party may bring an action in state or
            federal court to protect its intellectual property rights
            (“intellectual property rights” means patents, copyrights, moral
            rights, trademarks, and trade secrets, but not privacy or publicity
            rights). Either party may also elect to have disputes or claims
            resolved in a small claims court that are within the scope of that
            court&apos;s jurisdiction. Either party may also seek a declaratory
            judgment or other equitable relief in a court of competent
            jurisdiction regarding whether a party&apos;s claims are time-barred
            or may be brought in small claims court in your state and county of
            residence. Seeking such relief shall not waive a party&apos;s right
            to arbitration under this agreement.
          </TextBody>
        </TextSubBlock>
        <TextSubBlock>
          <TextSubTitle>9.6 30-Day Right to Opt Out</TextSubTitle>
          <TextBody>
            You have the right to opt-out and not be bound by the arbitration
            and class action waiver provisions set forth above by sending
            written notice of your decision to opt-out to the following address:
            Blitz App, Inc., 6080 Center Drive, Floor 12 Los Angeles, CA 90045.
            The notice must be sent within 30 days of your first use of the
            Services, whichever is later, otherwise you shall be bound to
            arbitrate disputes in accordance with the terms of those paragraphs.
            If you opt-out of these arbitration provisions, Blitz also will not
            be bound by them.
          </TextBody>
        </TextSubBlock>
        <TextSubBlock>
          <TextSubTitle>9.7 Changes to this Section</TextSubTitle>
          <TextBody>
            Blitz will provide 30 days&apos; notice of any changes to this
            section. Changes will become effective on the 30th day. If you
            continue to use the Services after the 30th day, you agree that any
            unfiled claims of which Blitz does not have actual notice are
            subject to the revised clause. <br />
            For any dispute not subject to arbitration you and Blitz agree to
            submit to the personal and exclusive jurisdiction of and venue in
            the federal and state courts located in Los Angeles, CA. You further
            agree to accept service of process by mail, and hereby waive any and
            all jurisdictional and venue defenses otherwise available. <br />
            The Terms and the relationship between you and Blitz shall be
            governed by the laws of the State of California without regard to
            conflict of law provisions.
          </TextBody>
        </TextSubBlock>
      </TextBlock>
      <TextBlock>
        <TextTitle>10. Miscellaneous</TextTitle>
        <TextSubBlock>
          <TextSubTitle>10.1 Entire Agreement</TextSubTitle>
          <TextBody>
            These Terms (and any Additional Terms that Blitz or a Prize Partner
            may post on the Services) constitute the entire agreement between
            you and Blitz with respect to the Services and supersedes any prior
            agreements, oral or written, between you and Blitz. In the event of
            a conflict between these Terms and the Additional Terms in
            particular contests, the latter will prevail over the Terms to the
            extent of the conflict.
          </TextBody>
        </TextSubBlock>
        <TextSubBlock>
          <TextSubTitle>10.2 Waiver and Severability of Terms</TextSubTitle>
          <TextBody>
            The failure of Blitz to exercise or enforce any right or provision
            of the Terms shall not constitute a waiver of such right or
            provision. If any provision of the Terms is found by an arbitrator
            or court of competent jurisdiction to be invalid, the parties
            nevertheless agree that the arbitrator or court should endeavor to
            give effect to the parties&apos; intentions as reflected in the
            provision, and the other provisions of the Terms remain in full
            force and effect.
          </TextBody>
        </TextSubBlock>
        <TextSubBlock>
          <TextSubTitle>10.3 Statute of Limitations</TextSubTitle>
          <TextBody>
            You agree that regardless of any statute or law to the contrary, any
            claim or cause of action arising out of or related to the use of the
            Services or the Terms must be filed within one (1) year after such
            claim or cause of action arose or be forever barred.
          </TextBody>
        </TextSubBlock>
        <TextSubBlock>
          <TextSubTitle>10.4 Section Titles</TextSubTitle>
          <TextBody>
            The section titles in the Terms are for convenience only and have no
            legal or contractual effect.
          </TextBody>
        </TextSubBlock>
        <TextSubBlock>
          <TextSubTitle>10.5 Communications</TextSubTitle>
          <TextBody>
            Users with questions, complaints or claims with respect to the
            Services may contact us using the relevant contact information set
            forth below.
            <br />
            <br />
            <address>
              Blitz App, Inc.
              <br />
              6080 Center Drive, Floor 12 Los Angeles, CA 90045
              <br />
              <Anchor to="mailto:support@blitz.gg">support@blitz.gg</Anchor>
            </address>
          </TextBody>
        </TextSubBlock>
        <TextSubBlock>
          <TextSubTitle>10.6 Termination</TextSubTitle>
          <TextBody>
            We may change or discontinue all or any part of the Services, at any
            time and without notice, at our sole discretion. Without limiting
            any other rights of Blitz, we may terminate your access to the
            Services if you fail to comply with any of these Terms. Upon any
            termination, the License will automatically terminate, you may no
            longer exercise any of the rights granted to you by the License, and
            you must destroy all copies of any materials in your possession.{" "}
            <strong>
              Except to the extent required by law, all payments and fees are
              non-refundable under all circumstances, regardless of whether or
              not this your access to the Services has been terminated.
            </strong>
            <br />
            The provisions of this Agreement which by their nature should
            survive the termination of this Agreement shall survive such
            termination, including but not limited to the disclaimers,
            limitations, and rules regarding dispute resolution in Sections 7, 8
            and 9 as well as the general provisions in this Section 10.
          </TextBody>
        </TextSubBlock>
        <TextSubBlock>
          <TextSubTitle>10.7 Links to Third Party Resources</TextSubTitle>
          <TextBody>
            The Services may allow you to access third-party websites or other
            resources. We provide access only as a convenience and are not
            responsible for the content, products or services on or available
            from those resources or links displayed on such websites. You
            acknowledge sole responsibility for and assume all risk arising
            from, your use of any third-party resources.
          </TextBody>
        </TextSubBlock>
        <TextSubBlock>
          <TextSubTitle>10.8 Messages</TextSubTitle>
          <TextBody>
            The Services may offer opportunities for you to send messages or
            postings in connection with various features including feedback,
            polls and petitions, and forums to communicate with other users
            (“Message Features”). In order to participate in any Message
            Feature, you may be required to provide accurate and complete
            personal information consisting of your name and e-mail address,
            which shall be collected pursuant to the Privacy Policy. Messages
            submitted to the Services by electronic mail or otherwise, including
            any data, questions, comments, suggestions or the like are, and will
            be treated as, nonconfidential and nonproprietary. By submitting a
            message to the Services, you are automatically granting the Operator
            a perpetual, royalty-free, non-exclusive, unrestricted, worldwide
            and irrevocable right and license to use, reproduce, modify,
            publish, translate, prepare derivative works based upon, distribute,
            perform or display such Message, in whole or in part, in any form,
            media or technology now known or hereafter developed for any
            purpose, including, but not limited to, advertising and promotional
            purposes, and to sublicense such rights to others.
          </TextBody>
        </TextSubBlock>
      </TextBlock>
    </ContentWrapper>
  );
};

export default TermsPage;
